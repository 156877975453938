import './App.css';
import React, { Component } from "react";

export default class App extends Component {
  componentDidMount() {
    const script = document.createElement("script");

    script.src = "https://static.zdassets.com/zendesk_app_framework_sdk/2.0/zaf_sdk.min.js";
    script.async = true;

    document.body.appendChild(script);
  }

  render() {
    return (
      <div className="App">
      <header className="App-header">
        <p>
          Environment url :- {process.env.REACT_APP_HOST_URL}.
        </p>
        <button>
          Connect Zero Friction
        </button>
      </header>
    </div>
    );
  }
}
